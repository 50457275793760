.text-section {
  @include grid-container();
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include breakpoint(medium) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.text-section__content {
  position: relative;
  padding: 1.5rem .5rem;

  @include breakpoint(medium) {
    padding: 3rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}