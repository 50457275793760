.tape__top-left,
.tape__top-right,
.tape__bottom-left,
.tape__bottom-right {
  display: none;

  @include breakpoint(large) {
    display: inline-block;
  }
}

.tape__mobile {
  @include breakpoint(large) {
    display: none;
  }
}

.tape__top-left {
  width: 10rem;
  margin-bottom: -5rem;
  margin-left: -5rem;
}
.tape__top-right {
  width: 10rem;
  float: right;
  margin-bottom: -5rem;
  margin-right: -2.5rem;
}

.tape__bottom-left {
  width: 7.5rem;
  margin-top: -5rem;
  margin-left: -5rem;
}
.tape__bottom-right {
  width: 15rem;
  float: right;
  margin-right: -2.5rem;
  margin-top: -15rem;
}