.icon {
  display: inline-block;
  width: .75em;
  height: .75em;
  margin-top: -.2em;

  @include breakpoint(medium) {
    width: 1em;
    height: 1em;
  }
}