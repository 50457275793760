.page-header {
  display: flex;
  flex-direction: column-reverse;
  justify-self: flex-start;
  padding-top: 1rem;

  @include breakpoint(medium) {
    flex-direction: column;
    padding-left: 2rem;
    padding-top: 0;
  }
}

.page-header__logo {
  @include grid-container();
  text-align: center;

  img {
    max-width: 25rem;
    width: 100%;
  }
}

.page-header__navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;

  @include breakpoint(medium) {
    text-align: right;
    align-self: flex-end;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 2rem;
    flex-direction: row;
  }
}

.page-header__link {
  text-decoration: none;
  margin-bottom: .5rem;
  font-size: $large-font-size;
  line-height: 1.2em;

  @include breakpoint(medium) {
    margin-right: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &--active {
    text-decoration: underline;
  }
}

.page-header__link--home {
  text-decoration: none !important;

  &:hover,
  &.page-header__link--active {
    span {
      text-decoration: underline;
    }
  }

  span {
    @include breakpoint(medium) {
      display: none;
    }
  }
}