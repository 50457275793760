.video-embed {
  position: relative;
  width: 100%;
}

.video-embed__content {
  display: block;
  height: 0;
  padding-bottom: (9 / 16) * 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}