.page-footer {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: 1.5rem;

  @include breakpoint(medium) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.page-footer__link {
  position: relative;
  margin-left: 2rem;
  width: 3rem;
  height: 3rem;

  @include breakpoint(medium) {
    width: 4rem;
    height: 4rem;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}