.full-section {
  width: 100%;
  padding: 1.5rem;

  @include breakpoint(medium) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  > img {
    display: block;
    width: 100%;
  }
}