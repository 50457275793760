$grid-columns: (
  small: 6,
  medium: 12,
);

$grid-column-gap: (
  small: 0.5rem,
  medium: 1rem,
);

$grid-container-padding: (
  small: 1.5rem,
  medium: 3rem,
  large: 6rem,
  xlarge: 9rem,
  xxlarge: 12rem,
);

@mixin grid-container($grid-container-padding: $grid-container-padding) {
  width: 100%;
  max-width: 1440 / 20 * 1rem;
  margin: 0 auto;
  @include -zf-breakpoint-value(auto, $grid-container-padding) {
    padding-right: $-zf-bp-value;
    padding-left: $-zf-bp-value;
  }
}

@mixin grid($grid-columns: $grid-columns) {
  display: grid;
  grid-column-gap: 1rem;

  @include -zf-breakpoint-value(auto, $grid-column-gap) {
    grid-column-gap: $-zf-bp-value;
  }

  @if type-of($grid-columns) == 'map' {
    @include -zf-breakpoint-value(auto, $grid-columns) {
      grid-template-columns: repeat($-zf-bp-value, 1fr);
    }
  } @else {
    grid-template-columns: repeat($grid-columns, 1fr);
  }
}

.grid {
  @include grid();
}

.grid-container {
  @include grid-container();
}
