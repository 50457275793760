$white: #FFF !default;
$grey: rgb(29, 29, 27) !default;
$black: #000 !default;

$blue: rgb(61, 130, 196) !default;

$breakpoints: (
  small: 0,
  medium: 720px,
  large: 990px,
  xlarge: 1200px,
  xxlarge: 1440px,
  hd: 1920px,
) !default;

$global-button-cursor: pointer !default;
$global-weight-normal: 700 !default;
$global-weight-bold: 700 !default;
$global-line-height: 1.25em !default;
$global-radius: 0 !default;
$global-font-size: (
  small: 18px,
  medium: 20px,
  large: 22px,
  xxlarge: 24px
) !default;

$body-background: $blue !default;
$body-font-color: $white !default;
$body-font-family: 'Baloo 2', sans-serif !default;

$paragraph-margin-bottom: $global-line-height !default;
$paragraph-line-height: $global-line-height !default;

$header-font-weight: 700 !default;
$header-font-family: $body-font-family;
$header-font-style: normal !default;

$anchor-color: inherit !default;
$anchor-color-hover: inherit !default;
$anchor-text-decoration: underline !default;

$small-font-size: .8em !default;
$large-font-size: 1.4em !default;
