.social-section {
  width: 100%;
  min-height: 100vh;
  padding: 1.5rem 0;

  overflow: visible !important;

  @include breakpoint(medium) {
    padding: 3rem 1.5rem;
  }
}