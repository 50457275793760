.inline-arrow {
  position: relative;
  display: block;
  vertical-align: top;
  margin-top: 1em;
  width: 3em;
  top: .2em;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint(medium) {
    display: inline;
    left: auto;
    transform: none;
    margin-top: 0;
  }
}