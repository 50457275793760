@import './settings';

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;700&display=swap');

@import '../../node_modules/normalize.css';
@import '../../node_modules/foundation-sites/scss/util/util';

@import 'base/global';
@import 'base/grid';
@import 'base/typography';

@import 'components/full-section';
@import 'components/icon';
@import 'components/inline';
@import 'components/page-footer';
@import 'components/page-header';
@import 'components/social-section';
@import 'components/tape';
@import 'components/text-section';
@import 'components/video-embed';

body {
  background: $blue;
}